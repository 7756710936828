import axios from "axios";
// import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import interceptors from "./interceptors";

// const raoEndpoint = KUrls.RAO_ENDPOINT;

const instance = axios.create({
  //   baseURL: `${KUrls.BASE_RA_REST_URL}${raoEndpoint}`,
  useGlobalInterceptors: true,
});
instance.interceptors.request.use(
  interceptors.dumpRequest,
  interceptors.dumpError
);
instance.interceptors.response.use(
  interceptors.dumpResponse,
  interceptors.dumpError
);
instance.interceptors.request.use(interceptors.setAuthToken);
instance.interceptors.response.use(null, interceptors.handleGenericError);
instance.interceptors.response.use(null, interceptors.handleUnauthorized);
instance.interceptors.response.use(null, interceptors.handleCustom550);

export const raoServices = {
  fetchUsers,
  fetchUserDetails,
  revokeUser,
  approveUser,
};

async function fetchUsers(filters, page, perPage) {
  try {
    // instance.defaults.baseURL = `${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}`;
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.RA_DOMAIN}${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}/users`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          page,
          perPage,
          filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.toJSON());
    return Promise.reject(error);
  }
}

async function fetchUserDetails(userId) {
  try {
    // instance.defaults.baseURL = `${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}`;
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.RA_DOMAIN}${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}/users/details`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          id: userId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.toJSON());
    return Promise.reject(error);
  }
}

async function revokeUser(userSubNickname, revokeCertificate = true) {
  try {
    // instance.defaults.baseURL = `${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}`;
    // const authorization = authHeader();
    const response = await instance.delete(
      `${KUrls.RA_DOMAIN}${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}/users?subNickname=${userSubNickname}`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          subNickname: userSubNickname,
          revokeCertificate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error.toJSON());
    return Promise.reject(error);
  }
}

async function approveUser(properties) {
  try {
    const response = await instance.post(
      `${KUrls.RA_DOMAIN}${KUrls.BASE_RA_REST_URL}${KUrls.RAO_ENDPOINT}/users/approve`,
      {
        ...properties,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
