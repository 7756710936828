import { extend, configure } from "vee-validate";
import {
  required,
  confirmed,
  max,
  numeric,
  email,
  ext,
  mimes,
  regex,
  is_not,
  min_value,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import it from "vee-validate/dist/locale/it.json";
import i18n from "./i18n";

// Install locales
i18n.mergeLocaleMessage("en", { validations: en });

i18n.mergeLocaleMessage("it", { validations: it });

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    // values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});

// Override the default message.
extend("confirmed", {
  ...confirmed,
  message: () => i18n.t("validation.input.wrong_password_confirmation"),
});

// Override the default message.
extend("required", {
  ...required,
  message: () => i18n.t("validation.input.required"),
});

extend("max", max);
extend("numeric", numeric);
extend("email", email);
extend("ext", ext);
extend("mimes", mimes);
extend("regex", regex);
extend("is_not", is_not);
extend("min_value", min_value);

/**
 * A nullable group represents a group where if a contained required property has a value, all the other required properties in the same group has to be valorized.
 * Instead, if no property in the group (even the required ones) has a value, it's all fine.
 */
extend("nullable_group", {
  params: ["groupId", "groupProps", "targetProp"],
  validate(targetValue, { groupId, groupProps, targetProp }) {
    const resultOK = {
      valid: true,
      required: false,
    };

    // If the there is a value it'ok
    if (targetValue) return resultOK;

    // If the group is 0 we do not consider the nullable group
    if (groupId === 0) return resultOK;

    // If the analyzed property is nullable we do not check it's value
    if (targetProp.nullable) return resultOK;

    // const groupProps = props.filter((p) => p.nullableGroup === groupId);

    for (const p of groupProps) {
      // Check only required properties in the group
      if (!p.nullable) {
        // Esaminare in base a come funziona il nullable group
        if (p.value && !targetValue)
          return {
            valid: false,
            required: true,
          };
      }
    }
    return resultOK;
  },
  computesRequired: true,
  message: () => i18n.t("validation.input.required"),
});

// extend("saved_or_disabled", {
//   validate(value,{saved}) {

//   }
// })
