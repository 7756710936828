import { ToastProgrammatic as Toast } from "buefy";
import i18n from "../i18n";
import router from "../router";
import { resetState } from "../store";

export function dumpRequest(request) {
  console.log("Starting Request", request);
  return request;
}

export function dumpResponse(response) {
  console.log("Response", response);
  return Promise.resolve(response);
}

export function dumpError(error) {
  console.log("Response Error", error.response);
  return Promise.reject(error);
}

export function handleUnauthorized(error) {
  const { status } = error.response;
  if (status === 401) {
    Toast.open({
      message: i18n.t("error.401-message"),
      type: "is-danger",
      duration: 5000,
    });
    // console.log(router.currentRoute.fullPath);
    resetState();
    router.push({
      name: "Login",
    });
  }
  return Promise.reject(error);
}

export function handleGenericError(error) {
  const { status } = error.response;
  if (status === 500) {
    showGenericError();
  }
  return Promise.reject(error);
}

export function showGenericError() {
  Toast.open({
    message: i18n.t("error.500-message"),
    type: "is-danger",
    duration: 5000,
  });
}

export function handleCustom550(error) {
  const { status } = error.response;
  if (status === 550) {
    Toast.open({
      message: error.response.data.message,
      type: "is-danger",
      duration: 5000,
    });
  }
  return Promise.reject(error);
}

export function setAuthToken(config) {
  const token = localStorage.getItem("user");

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
}

export default {
  setAuthToken,
  dumpError,
  dumpRequest,
  dumpResponse,
  handleUnauthorized,
  handleGenericError,
  handleCustom550,
  showGenericError,
};
