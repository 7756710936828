const policy = Object.freeze({ id: "POLICY", label: "policy" });
const appendix = Object.freeze({ id: "APPENDIX", label: "appendix" });
const privacy = Object.freeze({ id: "PRIVACY", label: "privacy" });

export const folderTypes = [policy, appendix, privacy];

export const KAgent = Object.freeze({ id: "AGENT", name: "agent" });
export const KSubAgent = Object.freeze({ id: "SUB_AGENT", name: "sub_agent" });
export const KCustomer = Object.freeze({ id: "CUSTOMER", name: "customer" });

export const insuranceRoleList = [KAgent, KSubAgent, KCustomer];

/**
 * Check if the given type is an appendix
 * @param {{id: String, label: string}} type The type to check
 */
export const isAppendix = function (type) {
  return type.id === appendix.id;
};

/**
 * Check if the given type is privacy
 * @param {{id: String, label: string}} type The type to check
 */
export const isPrivacy = function (type) {
  return type.id === privacy.id;
};

export const templateTypes = [
  Object.freeze({ key: "COMPANY", name: "C" }),
  Object.freeze({ key: "AGENCY", name: "A" }),
  Object.freeze({ key: "CUSTOMER", name: "U" }),
];

export const InsuranceFilters = Object.freeze([
  {
    id: "external_id",
    label: "external_id",
  },
  {
    id: "status",
    label: "status",
  },
  {
    id: "type",
    label: "type",
  },
  {
    id: "agent",
    label: "agent",
  },
  {
    id: "sub_agent",
    label: "sub_agent",
  },
  {
    id: "customer",
    label: "customer",
  },
]);
