// public static final int ORIGINAL_FILE = 1;
// public static final int SIGNATURE = 2;
// public static final int TIMESTAMP = 4;
// public static final int ENVELOPE = 8;
// public static final int SIGNING_CERTIFICATE = 16;
// public static final int METADATA = 32;

// public static final int ALL_SET_GENERIC = 2 ^ 6 - 1;

const KDownloadType = {
  ORIGINAL_FILE: 1,
  SIGNATURE: 2,
  TIMESTAMP: 4,
  ENVELOPE: 8,
  SIGNING_CERTIFICATE: 16,
  // METADATA: 32,
  // ALL_SET_GENERIC: Math.pow(2, 6) - 1,
};

const KDocumentDownloadType = {
  ...KDownloadType,
  SIGNED_FILE_ALT: Math.pow(2, 10),
  METADATA: Math.pow(2, 11),
  DEPOSIT_REPORT: Math.pow(2, 12),
};
// KDocumentDownloadType.SIGNED_FILE_ALT = Math.pow(2, 10);
// KDocumentDownloadType.METADATA = Math.pow(2, 11);

const KEInvoiceDownloadType = {
  ...KDownloadType,
  SDI_NOTIFICATIONS: Math.pow(2, 15),
  INVOICE_ATTACHMENTS: Math.pow(2, 16),
  PDF_EXPORT: Math.pow(2, 17),
  METADATA: Math.pow(2, 18),
  METADATA_WITH_VAT_SUMMARIES: Math.pow(2, 19),
};

function getEInvoiceDownloadType() {
  return buildDownloadTypeArray(false);
}

function getDocumentDownloadType() {
  return buildDownloadTypeArray(true);
}

function getVolumeDownloadType() {
  const array = [];
  for (const key of Object.keys(KDownloadType)) {
    const obj = {
      key: key,
      label: key,
      value: KDownloadType[key],
      visible: true,
      disabled: false,
    };
    array.push(obj);
  }
  return array;
}

function buildDownloadTypeArray(isDocument) {
  const array = [];
  const downloadType = isDocument
    ? KDocumentDownloadType
    : KEInvoiceDownloadType;
  for (const key of Object.keys(downloadType)) {
    const obj = {
      key: key,
      label: key,
      value: downloadType[key],
      visible: true,
      disabled: false,
    };
    array.push(obj);
  }
  return array;
}

function containsDonwloadType(value, downloadTypeValue) {
  return (value & downloadTypeValue) === downloadTypeValue;
}

export {
  KDownloadType,
  KDocumentDownloadType,
  KEInvoiceDownloadType,
  getDocumentDownloadType,
  getEInvoiceDownloadType,
  getVolumeDownloadType,
  containsDonwloadType,
};
