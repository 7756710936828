export const KPropNames = {
  id: "id",
  name: "name",
  class_id: "class_id",
};

const KDocStatus = Object.freeze({
  DOC_ERROR: "DOC_ERROR", // In errore
  DOC_NEW: "DOC_NEW", // In coda
  DOC_SIGNED: "DOC_SIGNED", // In attesa di versamento
  DOC_WORKING: "DOC_WORKING", // In lavorazione
  DOC_DEPOSITED: "DOC_DEPOSITED", // Versato
  DOC_READY: "DOC_READY", // In attesa di essere inserito in un volume
  VOL_CLOSED: "VOL_CLOSED", // In attesa di chiusura
  VOL_NO_LEGAL_ARCH: "VOL_NO_LEGAL_ARCH", // Da non conservare
  VOL_OPENED: "VOL_OPENED", // Inserito in un volume
  VOL_SIGNED: "VOL_SIGNED", // Sostitutivo
});

const KHistoryDocStatus = Object.freeze([
  KDocStatus.DOC_DEPOSITED, // Versato
  KDocStatus.DOC_READY, // In attesa di essere inserito in un volume
  KDocStatus.VOL_CLOSED, // In attesa di chiusura
  KDocStatus.VOL_NO_LEGAL_ARCH, // Da non conservare
  KDocStatus.VOL_OPENED, // Inserito in un volume
  KDocStatus.VOL_SIGNED, // Sostitutivo
]);

const KWorkingDocStatus = Object.freeze([
  KDocStatus.DOC_ERROR, // In errore
  KDocStatus.DOC_NEW, // In coda
  KDocStatus.DOC_SIGNED, // In attesa di versamento
  KDocStatus.DOC_WORKING, // In lavorazione
]);

export const KAllDocStatus = Object.values(KDocStatus);

export function getDocStatus(history) {
  if (history) {
    return KHistoryDocStatus;
  } else {
    return KWorkingDocStatus;
  }
}
