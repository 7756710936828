// export * from "./tableData";
// export * from "./eurotext";
import merge from "lodash/merge";
import * as tableConfig from "./tableData";
import * as eurotextConfig from "./eurotext";
import * as icons from "./icons";

// fetch("/public/config.json")
//   .then((json) => {
//     json().then((config) => {
//        Vue.prototype.$config = config
//        new Vue({
//          router,
//          store,
//          render: (h) => h(App)
//        }).$mount("#app")
//     })
// })

class Config {
  constructor() {
    this._props = { ...tableConfig, ...eurotextConfig, ...icons };
  }

  set properties(propsObject) {
    merge(this._props, propsObject);
    // this._props = propsObject;
  }

  get properties() {
    return this._props;
  }

  loadConfigFromFile = (filePath) => {
    fetch(filePath)
      .then((response) => response.json())
      .then((config) => {
        merge(this._props, config);
      });
  };
}

export default new Config();
