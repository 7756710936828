export default {
  namespaced: true,
  state: {
    tabs: [
      // {
      //   id: String,
      //   label: String,
      //   icon: String,
      //   component: String,
      //   props: Object,
      // }
      {
        id: "_home",
        label: null,
        icon: "home",
        component: null,
        props: {},
        hidden: true,
      },
    ], // faccio push dal componente di un oggetto di questo tipo e dal tab manager ho un listener sullo stato che aggiorna le tab
    currentTab: 0,
  },
  getters: {
    getTabById: (state) => (id) => {
      for (let tab of state.tabs) {
        if (tab.id === id) {
          return tab;
        }
      }
      return null;
    },
  },
  mutations: {
    addTab(state, tabData) {
      let newTab = { ...tabData };
      if (!newTab.pops) {
        newTab.props = {};
      }
      state.tabs.push(tabData);
    },
    changeCurrentTab(state, newCurrentTab) {
      state.currentTab = newCurrentTab;
    },
    removeTab(state, tabIndex) {
      state.tabs.splice(tabIndex, 1);
    },
  },
  actions: {
    /**
     * Open a new tab if it's not already opened. Id parameter is used to check identity of tabs.
     * If the tab is already present in the array, the currentTab state parameter is updated with the relative index.
     * Note: in case of a new tab, that is not opened by default.
     * @param {String} tabData The new tab object to open. {
        id: String,
        label: String,
        icon: String,
        component: String,
        props: Object,
      }
     */
    openTab({ commit, state }, tabData) {
      const tabId = tabData.id;
      let index = state.tabs.findIndex((tab) => tab.id === tabId);
      if (index < 0) {
        commit("addTab", tabData);
      } else {
        commit("changeCurrentTab", index);
      }
    },
    /**
     * Remove a tab from the state if it's found in the array
     * @param {String} tabId
     */
    removeTab({ commit, state }, tabId) {
      let index = state.tabs.findIndex((tab) => tab.id === tabId);
      console.log("Find index", tabId);
      console.log("Found pos", index);
      if (index >= 0) commit("removeTab", index);
    },
  },
};
