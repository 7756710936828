import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
// import { prepareEinvoiceFiltersToSend } from "@/helpers";

export const dossierService = {
  getDossierDocuments,
};

const dossierEndpoint = KUrls.DOSSIER_ENDPOINT || "/dossiers";

// async function getVolumes(
//   companyName,
//   elementsNumber,
//   pageNumber,
//   areSignedVolumes
// ) {
//   try {
//     const authorization = authHeader();

//     const volumeType = areSignedVolumes ? "signed" : "working";

//     const response = await axios.get(
//       `${KUrls.BASE_REST_URL}${volumeEndpoint}/${volumeType}/${companyName}`,
//       {
//         headers: {
//           // "Content-Type": "application/json",
//           Authorization: authorization,
//         },
//         params: {
//           elementsNumber: elementsNumber,
//           pageNumber: pageNumber,
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     return Promise.reject(error);
//   }
// }

async function getDossierDocuments(
  companyName,
  dossierId,
  elementsNumber,
  pageNumber
) {
  try {
    const authorization = authHeader();

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${dossierEndpoint}/${companyName}/documents`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          dossierId: dossierId,
          elementsNumber: elementsNumber,
          pageNumber: pageNumber,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
