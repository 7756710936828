import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
// import { eurotextConfig } from "../config";
import { b64Encode } from "../helpers";

export const inputService = {
  uploadFile,
  uploadMetadata,
  uploadDummy,
  createSdIEInvoice,
};

const inputEndpoint = KUrls.INPUT_ENDOPOINT || "/input";

async function uploadFile(
  companyName,
  b64File,
  mime_type,
  sha256,
  callback = () => {}
) {
  try {
    // TODO set axios default headers https://github.com/axios/axios#global-axios-defaults
    const authorization = authHeader();
    // const kindOfInput = eurotextConfig.kindOfInput;
    const response = await axios.post(
      `${KUrls.BASE_REST_URL}${inputEndpoint}/documents/upload/${companyName}`,
      {
        file: b64File,
        // kindOfInput,
        mime_type,
        hashSHA256HexIn: sha256,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        onUploadProgress: callback,
      }
    );
    const location = response.headers.location;
    console.log(location);
    const data = response.data;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function uploadMetadata(
  companyName,
  className,
  metadata,
  progressive,
  fiscalYear
) {
  const authorization = authHeader();
  // const kindOfInput = eurotextConfig.kindOfInput;
  const b64metadata = b64Encode(metadata);
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${inputEndpoint}/documents/metadata/${companyName}`,
    {
      className,
      batchId: -1,
      metadataStream: b64metadata,
      progressive: parseInt(progressive) || -1,
      fiscalYear: parseInt(fiscalYear) || 0,
      // kindOfInput,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 500; // we catch the 500 because has a custom body this time
      },
    }
  );
  let data = { ...response.data };
  return data;
}

async function uploadDummy(companyName, className, progressive) {
  const authorization = authHeader();
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${inputEndpoint}/documents/dummy/${companyName}`,
    {
      className,
      progressive: parseInt(progressive) || -1,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 500; // we catch the 500 because has a custom body this time
      },
    }
  );
  let data = { ...response.data };
  return data;
}

async function createSdIEInvoice(companyName, className, documentId = null) {
  const authorization = authHeader();
  const url = `${KUrls.DOMAIN}/javasign/invoiceGenerator`;
  const params = new URLSearchParams({
    userSchema: companyName,
    className: className,
    token: authorization,
  });
  if (documentId !== null) params.set("etDocId", documentId);
  window.open(`${url}?${params.toString()}`, "_blank");
}

// async function uploadFiles(files) {
//   const fileArray ;
//   if(Array.isArray(files)) {
//     fileArray = files;
//   } else {
//     fileArray = [...files];
//   }
//   for(file of fileArray){
//     const b64 = await getBase64FromFile(this.file);
//     const sha256 = await hexSHA256FromFile(this.file);
//     const fileLocation = uploadFile(b64, file.type, sha256);
//     createMetadata()
//   }
// }
