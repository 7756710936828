import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import { formatFilters } from "@/helpers";
// import { classService } from "./class.service";
// import { eurotextConfig } from "../config";

export const downloadService = {
  downloadDocuments,
  downloadEInvoices,
  downloadAllDocuments,
  downloadAllEInvoices,
  downloadVolume,
};

const downloadEndpoint = KUrls.DOWNLOAD_ENDPOINT || "/download";

/**
 * Download or display the selected format typs related to the selected documents.
 * @param {String} companySchema
 * @param {String} locale
 * @param {String} dateStylePattern
 * @param {Boolean} timeStyle24H - Whether to display the hours in 24h format or 12h.
 * @param {Array} ids - IDs of the file to download or display.
 * @param {Number} artifacts - Bitwise representation of the format types to download.
 * @param {Array} metadataColumns - Array of metadata values to donwload.
 * @param {Boolean} isDisplay - Whether to display or donwload the document. Default is download.
 */
async function downloadDocuments(
  companySchema,
  locale,
  dateStylePattern,
  timeStyle24H,
  ids,
  artifacts,
  metadataColumns,
  isDisplay = false
) {
  try {
    const authorization = authHeader();
    const response = await axios.post(
      `${KUrls.BASE_REST_URL}${downloadEndpoint}/documents/${companySchema}`,
      {
        locale: locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateStylePattern,
        timeStyle24H,
        ids,
        downloadArtifacts: artifacts,
        metadataColumns,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    const retData = {};
    if (isDisplay && ids.length === 1) {
      retData.display = display(response.data, companySchema);
    } else {
      download(response.data, companySchema);
    }
    return retData;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function downloadAllDocuments(
  companySchema,
  className,
  queryFilterGroup,
  locale,
  dateStylePattern,
  timeStyle24H,
  artifacts,
  metadataColumns,
  isHistory,
  isWorking
) {
  const authorization = authHeader();

  const formattedQFG = {
    operator: queryFilterGroup.operator,
  };
  formattedQFG.filters = formatFilters(queryFilterGroup.filters);

  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${downloadEndpoint}/documents/${companySchema}/all`,
    {
      queryFilterGroup: formattedQFG,
      className,
      locale: locale,
      dateStylePattern,
      timeStyle24H,
      downloadArtifacts: artifacts,
      metadataColumns,
      history: isHistory,
      working: isWorking,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
  console.log(response);
  download(response.data, companySchema);
}

async function downloadEInvoices(
  companySchema,
  locale,
  dateStylePattern,
  timeStyle24H,
  ids,
  artifacts,
  isCredit
) {
  try {
    const authorization = authHeader();
    const response = await axios.post(
      `${KUrls.BASE_REST_URL}${downloadEndpoint}/einvoices/${companySchema}`,
      {
        locale: locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateStylePattern,
        timeStyle24H,
        ids,
        downloadArtifacts: artifacts,
        credit: isCredit,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    download(response.data, companySchema);
    return true;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function downloadAllEInvoices(
  className,
  statusFilter,
  einvoiceFilters,
  companySchema,
  locale,
  dateStylePattern,
  timeStyle24H,
  artifacts,
  isCredit
) {
  const filters = formatFilters(einvoiceFilters);
  // const filters = prepareEinvoiceFiltersToSend(einvoiceFilters);
  const queryFilterGroup = {
    operator: "AND",
    filters: filters,
  };
  const authorization = authHeader();
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${downloadEndpoint}/einvoices/${companySchema}/all`,
    {
      className,
      status: statusFilter,
      queryFilterGroup,
      locale: locale,
      dateStylePattern,
      timeStyle24H,
      downloadArtifacts: artifacts,
      credit: isCredit,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
  download(response.data, companySchema);
}

async function downloadVolume(
  companySchema,
  locale,
  dateStylePattern,
  timeStyle24H,
  ids,
  artifacts
) {
  try {
    const authorization = authHeader();
    const response = await axios.post(
      `${KUrls.BASE_REST_URL}${downloadEndpoint}/volumes/${companySchema}`,
      {
        locale: locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateStylePattern,
        timeStyle24H,
        ids,
        downloadArtifacts: artifacts,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    download(response.data, companySchema);
    return true;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

function download(data, companySchema) {
  const authorization = authHeader();
  const url = `${KUrls.DOMAIN}${KUrls.BASE_SERVLET_URL}/protected/download`;
  const params = new URLSearchParams({
    token: authorization,
    company: companySchema,
    ...data,
  });

  console.log(`${url}?${params.toString()}`);
  window.open(`${url}?${params.toString()}`, "_blank");
}

function display(data, companySchema) {
  const authorization = authHeader();
  const url = `${KUrls.DOMAIN}${KUrls.BASE_SERVLET_URL}/protected/display`;
  const params = new URLSearchParams({
    token: authorization,
    company: companySchema,
    ...data,
  });

  // window.open(`${url}?${params.toString()}`, "_blank"); //TODO: remove this line
  return {
    url: `${url}?${params.toString()}`,
    fileName: data["filenameOutput"],
    mimeType: data["mimeType"],
  };
}
