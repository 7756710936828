<template>
  <section class="hero is-eurotext-light is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title title-404">404</p>
        <div class="subtitle sub-404">
          <span>{{ $t("404.text") }}</span>
          <router-link class="button is-primary" :to="{ name: 'Welcome' }">
            {{ $t("404.buttons.home") }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.title-404 {
  font-size: 8rem;
}
.sub-404 {
  display: inline-flex;
  align-items: center;

  & *:not(:last-child) {
    margin-right: 0.5rem;
  }
}
</style>
