<template>
  <form @submit.prevent="handleSubmit" class="box">
    <slot name="error"></slot>
    <b-field>
      <b-input
        v-model="username"
        placeholder="Username"
        icon="account"
        autofocus
        required
      ></b-input>
    </b-field>
    <b-field>
      <b-input
        v-model="password"
        type="password"
        icon="lock"
        placeholder="Password"
        required
      ></b-input>
    </b-field>
    <b-button
      native-type="submit"
      class="is-primary is-medium"
      icon-right="login-variant"
      style="margin=22px;"
      >Login</b-button
    >
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  props: {
    redirection: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions("users", ["login"]),
    handleSubmit() {
      this.$emit("login", this.username, this.password);
      // this.isLoading = true;
      // const { username, password, redirection } = this;
      // if (username && password) {
      //   this.login({ username, password, redirection })
      //     .then()
      //     .finally(() => (this.isLoading = false));
      // }
    },
  },
};
</script>

<style></style>
