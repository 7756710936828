import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import { formatFilters } from "@/helpers";

export const einvoiceService = {
  getStatus,
  fetchClassInvoices,
};

const einvoiceEndpoint = KUrls.EINVOICE_ENDPOINT || "/einvoices";

async function getStatus(
  company,
  officeCode,
  minDatetimeIncl,
  maxDatetimeExcl,
  className
) {
  try {
    const authorization = authHeader();

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${einvoiceEndpoint}/${company}/status`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          officeCode: officeCode,
          minDatetimeIncl: minDatetimeIncl,
          maxDatetimeExcl: maxDatetimeExcl,
          className: className,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function fetchClassInvoices(
  company,
  className,
  statusFilter,
  einvoiceFilters,
  page,
  perPage,
  sortingPriority,
  isCreditClass
) {
  try {
    const authorization = authHeader();

    const filters = formatFilters(einvoiceFilters);
    // const filters = prepareEinvoiceFiltersToSend(einvoiceFilters);
    const queryFilterGroup = {
      operator: "AND",
      filters: filters,
    };
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${einvoiceEndpoint}/${company}/search`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          // className: className,
          status: statusFilter,
          page,
          perPage,
          queryFilterGroup,
          orderBy: JSON.stringify(sortingPriority),
          credit: isCreditClass,
        },
      }
    );
    const data = response && response.data;
    if (!response.status && response.status != 200) {
      if (response.status === 401) {
        console.log("unauthorized");
        // unauthorized
      }
      const error = (data && data.message) || response.statusText;
      throw error;
    }

    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
