const einvoice = "email-newsletter";
const document = "file-document";
const volumes_working = "thermostat";
const volumes_signed = "disc";

const icons = {
  menu: {
    configuration: "hammer-wrench",
    class_search: "account-search",
    class_documents: "file-sync-outline",
    class_upload: "upload-outline",
    class_einvoice_details: "email-newsletter",
    einvoice_status: "email-sync-outline",
    class_einvoice_status: "email-sync-outline",
    change_pec_password: "key-change",
    class_signature: "draw",
    signature: "draw",
    rc_signature: "draw",
    dossiers: "file-document-multiple-outline",
    search: "magnify",
    volumes_signed: "disc",
    volumes_working: "thermostat",
    statistics: "chart-line",
    global_search: "account-search",
    pdf_template: "shape-plus",
    class_policy_upload: "upload-outline",
    pdf_utils: "view-split-vertical",
    policy_search: "magnify",
    policy_settings: "cog",
    rao_new_user: "account-plus",
    rao_search_user: "account-search",
    _company: "factory",
    _class: "book-open-page-variant-outline",
    _default: "folder",
  },
  tabs: {
    einvoice_detail: einvoice,
    document_detail: document,
    volumes_signed,
    volumes_working,
    user_detail: "account",
  },
  buttons: {
    new_sdi_einvoice: "email-plus",
    SHOW_INVOICE: "file-document",
    DOWNLOAD_FILES: "file-download",
    DOWNLOAD_FILES_SDI: "file-download",
    EXPORT_AS_PDF: "pdf-box",
    RESEND: "email-send",
    LIST_NS_ERRORS: "alert-circle",
    SET_REVERSED: "file-cancel",
    SET_SOLVED: "send-check",
    RESEND_EC: "file-send-outline",
    SEND_EC: "file-send",
    SET_REVERSED_VIM_RESEND: "email-sync",
  },
};

export { icons };
