import Hashes from "jshashes";
import { create } from "xmlbuilder2";
import { endOfDay, startOfDay } from "date-fns";
import { cloneDeep, toNumber } from "lodash";
import * as dateUtils from "./date";
import { checkType, Operators } from "./constants/valueTypes";

export function getBase64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // const b64 = reader.result.replace(/^data:.+;base64,/, "");
      const b64 = btoa(reader.result);
      resolve(b64);
    };
    reader.onerror = () => reject(reader.error);

    // reader.readAsDataURL(file);
    reader.readAsBinaryString(file);
  });
}

export function base64ToFile(base64String, fileName, mimeType) {
  const fData = Uint8Array.from(
    atob(base64String)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const file = new File([fData], fileName, {
    type: mimeType,
  });
  return file;
}

export function hexSHA256FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const sha256 = new Hashes.SHA256().hex(reader.result);
      resolve(sha256);
    };
    reader.onerror = () => reject(reader.error);

    reader.readAsBinaryString(file);
  });
}

// export function createMetadata(payload) {
//   const obj = {
//     root: {
//       row: {
//         "@progressive": payload.progressive || "0",
//         "@class_name": payload.className,
//         "@input_to_be_deleted": payload.inputToBeDeleted || "", // Y/N
//         "@mime_type": payload.mime_type,
//         "@original_file": payload.originalFile,
//         "@input_date": payload.inputData || getCurrentDateFormatted(),
//         "@this_year": payload.thisYear,
//         "@service": payload.service || "NO", // [NO, email, acc, pec, pec_pa]
//       },
//     },
//   };
//   const doc = xmlbuilder.create({ encoding: "UTF-8" }, obj);
//   const xml = doc.end();
//   console.log(xml);
//   return xml;
// }

export function createMetadata(
  properties,
  className,
  mimeType,
  originalFileLocation,
  documentId
) {
  // Default values of mandatory properties;
  const obj = {
    root: {
      row: {
        "@class_name": className,
        "@document_id": documentId,
        "@mime_type": mimeType,
        "@input_to_be_deleted": "Y", // Y/N
        "@original_file": originalFileLocation,
        "@input_date": dateUtils.toStringISODate(new Date()),
        "@service": "NO", // [NO, email, acc, pec, pec_pa]
      },
    },
  };
  for (const prop of properties) {
    if (prop.value && prop.value instanceof Date) {
      if (checkType.isDateTime(prop.type))
        obj.root.row[`@${prop.name}`] = dateUtils.toStringISODateTime(
          prop.value
        );
      else
        obj.root.row[`@${prop.name}`] = dateUtils.toStringISODate(prop.value);
    } else if (prop.name === "is_sdi_einvoice" && prop.value) {
      if (prop.value === "true" || prop.value === true) {
        obj.root.row[`@${prop.name}`] = 1;
      } else if (prop.value === "false" || prop.value === false) {
        obj.root.row[`@${prop.name}`] = 0;
      } else {
        obj.root.row[`@${prop.name}`] = prop.value;
      }
    } else {
      obj.root.row[`@${prop.name}`] = prop.value;
    }
  }
  const doc = create({ encoding: "UTF-8" }, obj);
  const xml = doc.end();
  console.log(xml);
  return xml;
}

export function getCurrentDateFormatted() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
}

export function prepareEinvoiceFiltersToSend(einvoiceFilters) {
  // Copy to avoid modifying inner object passed by reference
  const filtersDeepCopy = deepCopy(einvoiceFilters);
  const filters = filtersDeepCopy.map((obj) => {
    const newObj = { ...obj };

    if (newObj.operator === "BETWEEN") {
      const values = newObj.filterValue.value1;
      if (checkType.isDate(newObj.filterValueType)) {
        // DATE
        newObj.filterValue.value1 = dateUtils.toStringISODate(values[0]);
        newObj.filterValue.value2 = dateUtils.toStringISODate(values[1]);
      } else if (checkType.isDateTime(newObj.type)) {
        // TIMEDATE
        // For types datetime we have to put a between the start and the end of the day beacuse at the moment all the time is always 00:00:000
        newObj.filterValue.value1 = dateUtils.toStringISODateTime(
          startOfDay(values[0])
        );
        newObj.filterValue.value2 = dateUtils.toStringISODateTime(
          endOfDay(values[1])
        );
      }
    } else {
      if (newObj.filterValue.value1) {
        if (
          Object.prototype.toString.call(newObj.filterValue.value1) ===
          "[object Date]"
        ) {
          console.log("date");
          newObj.filterValue.value1 = dateUtils.toStringISODate(
            newObj.filterValue.value1
          );
          // newObj.filterValue.value1 = formatISO(newObj.filterValue.value1, {
          //   format: "basic",
          //   representation: "date",
          // });
        }
        if (newObj.operator === "LIKE") {
          if (!newObj.filterValue.value1.startsWith("%")) {
            newObj.filterValue.value1 = "%".concat(newObj.filterValue.value1);
          }
          if (!newObj.filterValue.value1.endsWith("%")) {
            newObj.filterValue.value1 = newObj.filterValue.value1.concat("%");
          }
        }
        if (
          newObj.filterValue.value1.includes("*") &&
          newObj.operator !== "LIKE"
        ) {
          const newVal = newObj.filterValue.value1.replaceAll("*", "%");
          newObj.filterValue.value1 = newVal;
          newObj.operator = "LIKE";
        }
      }
    }

    return newObj;
  });
  return filters;
}

/**
 * @deprecated
 */
export function formatDocumentFilters(filterArray) {
  const newArray = deepCopy(filterArray);
  for (let filter of newArray) {
    if (filter.type === "data") {
      if (filter.valueRange && filter.valueRange.length > 0) {
        filter.valueRange = filter.valueRange.map((o) => {
          let newVal = o;
          if (isDateObject(o)) {
            newVal = dateUtils.toStringISODate(o);
          }
          return newVal;
        });
      }
      if (filter.value && isDateObject(filter.value)) {
        filter.value = dateUtils.toStringISODate(filter.value);
      }
    }
  }
  return newArray;
}

export function formatFilters(filters) {
  return filters.map((filter) => {
    const ret = {
      filterKey: filter.name,
      filterValueType: filter.type,
      operator: filter.operator,
      filterValue: {
        value1: filter.value,
        value2: undefined,
        values: undefined,
      },
    };

    if (filter.value) {
      if (Array.isArray(filter.value)) {
        // Multiple values are used only for date types at the moment
        if (checkType.isDateTime(filter.type)) {
          // TIMEDATE
          // For types datetime we have to put a between the start and the end of the day beacuse at the moment all the time is always 00:00:000
          ret.filterValue.value1 = dateUtils.toStringISODateTime(
            startOfDay(filter.value[0])
          );
          ret.filterValue.value2 = dateUtils.toStringISODateTime(
            endOfDay(filter.value[1])
          );
          ret.operator = Operators.BETWEEN.key;
        } else if (checkType.isDate(filter.type)) {
          // DATE
          ret.filterValue.value1 = dateUtils.toStringISODate(filter.value[0]);
          ret.filterValue.value2 = dateUtils.toStringISODate(filter.value[1]);
        } else {
          ret.filterValue.values = filter.value;
          ret.filterValue.value1 = null;
          ret.operator = Operators.IN.key;
        }
      } else if (isDateObject(filter.value)) {
        // Date object NOT ARRAY
        if (checkType.isDateTime(filter.type)) {
          ret.filterValue.value1 = dateUtils.toStringISODateTime(
            startOfDay(filter.value)
          );
          ret.filterValue.value2 = dateUtils.toStringISODateTime(
            endOfDay(filter.value)
          );
          ret.operator = Operators.BETWEEN.key;
        } else if (checkType.isDate(filter.type)) {
          ret.filterValue.value1 = dateUtils.toStringISODate(filter.value);
        }
      } else if (isStringObject(filter.value)) {
        // String object NOT ARRAY
        if (
          filter.operator === Operators.LIKE.key ||
          filter.operator === Operators.NOT_LIKE.key
        ) {
          if (!filter.value.startsWith("%")) {
            ret.filterValue.value1 = "%".concat(filter.value);
          }
          if (!filter.value.endsWith("%")) {
            ret.filterValue.value1 = ret.filterValue.value1.concat("%");
          }
          ret.filterValueType = "STRING";
        } else if (
          filter.value.includes("*") &&
          (filter.operator === Operators.EQUALS.key ||
            filter.operator === Operators.NOT_EQUALS.key)
        ) {
          const newVal = filter.value.replaceAll("*", "%");
          ret.filterValue.value1 = newVal;
          ret.operator =
            filter.operator === Operators.EQUALS.key
              ? Operators.LIKE.key
              : Operators.NOT_LIKE.key;
          ret.filterValueType = "STRING";
        }
      }
    }
    return ret;
  });
}

export function b64Encode(text) {
  // Most likely outdated due to interface of String.fromCodePoint not accepting arrays anymore
  // const binString = String.fromCodePoint(new TextEncoder().encode(text));
  // return btoa(binString);
  const byteArr = new TextEncoder().encode(text);
  const b64 = Buffer.from(byteArr).toString("base64");
  return b64;
}

export function b64ToFile(b64, fileName, mimeType) {
  const fData = Uint8Array.from(
    atob(b64)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const file = new File([fData], fileName, {
    type: mimeType,
  });
  return file;
}

export function deepCopy(value) {
  return cloneDeep(value);
}

export function to2DecimalFloat(val) {
  return parseFloat(toNumber(val)).toFixed(2);
}

export function isDateObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Date]";
}

function isStringObject(obj) {
  return Object.prototype.toString.call(obj) == "[object String]";
}
