import { authService } from "@/services";
import { loginResponse } from "@/services/api-schema";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    user: {},
    status: {
      loggedIn: false,
      loggingIn: false,
      error: false,
    },
    userSchemas: localStorage.getItem("user_schemas")
      ? JSON.parse(localStorage.getItem("user_schemas"))
      : [],
    merchant: "",
    subNickname: "",
  },
  getters: {
    getStatus(state) {
      return state.status;
    },
    getRASubRA(state) {
      let res = null;
      if (state.subNickname) {
        const sn = state.subNickname;
        res = sn.substr(0, sn.lastIndexOf(":"));
      }
      return res;
    },
    getSubRA(state) {
      let res = null;
      if (state.subNickname) {
        const sn = state.subNickname;
        res = sn.substring(sn.indexOf(":") + 1, sn.lastIndexOf(":"));
      }
      return res;
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status.loggingIn = true;
      state.status.error = false;
      state.user = user;
    },
    loginSuccess(state, { user, schemas, merchant, subNickname }) {
      state.status.loggedIn = true;
      state.status.loggingIn = false;
      state.status.error = false;
      state.user = user;
      state.userSchemas = schemas;
      state.merchant = merchant;
      state.subNickname = subNickname;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.status.loggingIn = false;
      state.status.error = true;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = true;
      state.status.loggingIn = false;
      state.user = null;
      state.userSchemas = null;
    },
  },
  actions: {
    login({ commit }, { username, password, redirection }) {
      commit("loginRequest", { username });

      authService.login(username, password).then(
        (user) => {
          commit("loginSuccess", {
            user: username,
            schemas: user[loginResponse.userSchemas],
          });
          router.push(redirection);
        },
        (error) => {
          commit("loginFailure", error);
          // dispatch("alert/error", error, { root: true });
        }
      );
    },
    logout({ commit }) {
      authService.logout();
      commit("logout");
    },
  },
};
