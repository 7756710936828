export const socketStatusCodes = Object.freeze({
  // SIGN_STATUS_NOT_ACTIVE: 1, // In attesa del PIN
  // SIGN_STATUS_WRONG_PIN: 2, // PIN errato
  // SIGN_STATUS_WRONG_USER: 3, // Utente non abilitato

  // SIGN_STATUS_ACTIVE: 101, // In esecuzione
  // SIGN_STATUS_WRONG_STOP_PIN: 102, // PIN di arresto errato
  // SIGN_STATUS_PARTIALLY_ACTIVE: 103, // Attivo fino al progressivo x
  ACTIVATED: "ACTIVATED",
  ACTIVATED_PARTIAL: "ACTIVATED_PARTIAL",
  DEACTIVATED: "DEACTIVATED",
  WRONG_PIN: "WRONG_PIN",
  UNAUTHORIZED_USER: "UNAUTHORIZED_USER",
  WRONG_STOP_PIN: "WRONG_STOP_PIN",
});

export const socketActions = Object.freeze({
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
  ACTIVATE_PARTIAL: "ACTIVATE_PARTIAL",
  STOP_NOTIFICATIONS: "STOP_NOTIFICATIONS",
  START_NOTIFICATIONS: "START_NOTIFICATIONS",
});

export const basicSocketActions = Object.freeze({
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
  ACTIVATE_PARTIAL: "ACTIVATE_PARTIAL",
});
