import { userService } from "@/services";
// import router from "@/router";

export default {
  namespaced: true,
  state: {
    data: { companies: [] },
    isLoading: false,
    isError: false,
  },
  getters: {
    getClassById: (state) => (id, companyName) => {
      for (let company of state.data.companies) {
        if (company.name === companyName) {
          const c = company.classes.find((clazz) => clazz.id === id);
          if (c) {
            return c;
          }
        }
      }
      return null;
    },
    /**
     * Class name it's not unique across different companies, so the companyName is needed to search.
     * @param {String} name The name of the class
     * @param {String} companyName The name of the company where to search the class
     */
    getClassByName: (state) => (name, companyName) => {
      for (let company of state.data.companies) {
        if (company.name === companyName) {
          const c = company.classes.find((clazz) => clazz.name === name);
          if (c) {
            return c;
          }
        }
      }
      return null;
    },
    getClassNameById: (state) => (id, companyName) => {
      for (let company of state.data.companies) {
        if (company.name === companyName) {
          const c = company.classes.find((clazz) => clazz.id === id).name;
          if (c) {
            return c;
          }
        }
      }
      return null;
    },
    /**
     * Class name it's not unique across different companies, so the companyName is needed to search.
     * @param {String} name The name of the class
     * @param {String} companyName The name of the company where to search the class
     */
    getClassIdByName: (state) => (name, companyName) => {
      for (let company of state.data.companies) {
        if (company.name === companyName) {
          const c = company.classes.find((clazz) => clazz.name === name).id;
          if (c) {
            return c;
          }
        }
      }
      return null;
    },
    getCompanyClasses: (state) => (company) => {
      const ret = state.data.companies.find((c) => c.name === company);
      if (ret) return ret.classes;
      else return null;
    },
  },
  mutations: {
    setMenu(state, menuData) {
      state.data = menuData;
    },
    menuRequestInit(state) {
      state.isLoading = true;
    },
    menuRequestSuccess(state, menuData) {
      state.data = menuData;
      state.isError = false;
      state.isLoading = false;
    },
    menuRequestFailure(state) {
      state.isError = true;
      state.data = {};
    },
  },
  actions: {
    // isActiveClass({ getters }, className) {
    //   const clazz = getters.getClassByName(className);
    //   if (clazz) {
    //     return clazz.isCreditInvoice;
    //   }
    // },
    // isPassiveClass({ getters }, className) {
    //   const clazz = getters.getClassByName(className);
    //   if (clazz) {
    //     return clazz.isDebitInvoice;
    //   }
    // },
    fetchMenu({ commit }) {
      commit("menuRequestInit");

      userService.fetchMenu().then(
        (menuData) => {
          commit("menuRequestSuccess", menuData);
          //   router.push(redirection);
        },
        (error) => {
          commit("menuRequestFailure", error);
          //   dispatch("alert/error", error, { root: true });
        }
      );
    },
  },
};
