export function authHeader() {
  // return authorization header with jwt token
  const user = localStorage.getItem("user");
  if (user) {
    return `Bearer ${user}`;
  } else {
    return {};
  }
}

export function saveAuthToken(token) {
  localStorage.setItem("user", token);
}
